import axios from 'axios';
import { formatIntent, getDynamicMessage, removeEmojisFromMessage } from '../utils/helper.util';
import { MESSAGE_INTENT, REPORT_ISSUE_MESSAGE } from '../utils/messages';


class Services {

  CancelToken = axios.CancelToken;
  source = this.CancelToken.source();

  static getInstance() {
    return new Services();
  }

  private getCitibotToken = async () => {
    const url = process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL + 'auth-token?apikey=' + process.env.REACT_APP_CITIBOT_BACKEND_API_KEY;

    const response = await axios.get(url);
    return (response.data as any).token;
  };

  getCitibotAccount = async (orgId: number | string) => {
    const url = process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL + `city-account/${orgId}?apikey=` + process.env.REACT_APP_CITIBOT_BACKEND_API_KEY;
    return axios.get(url);
  };

  getCitibotSearchDocument = async (searchUrl: string) => {
    const searchUrlParts = searchUrl.split('?')[0].split('/');
    const docId = searchUrlParts[searchUrlParts.length - 1];

    const url = `${process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL}search-doc?docId=${docId}&apikey=${process.env.REACT_APP_CITIBOT_BACKEND_API_KEY}`;
    return axios.get(url);
  };

  getIntent = async (contact: string, to: number, reply: string) => {
    reply = removeEmojisFromMessage(reply);

    const url = `${process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL}lex-intent?apikey=${process.env.REACT_APP_CITIBOT_BACKEND_API_KEY}`;
    try {
      return axios.post(url, {
          'from': contact,
          'to': to,
          'message': reply
        }
      );
    } catch (error) {
      console.error('Prod Engine token error', (error as any).message);
    }
  };

  getIntentQuestions = (intentName: string, orgId: number) => {
    const url = process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL + `organizations/${orgId}/intents/lex/questions`;
    return axios.get(url!, {
      cancelToken: this.source.token,
      params: {
        intent: intentName
      }
    });
  };

  searchIssue = (message: string, accountId: number, contactNo: number) => {
    message = removeEmojisFromMessage(message);

    const url = process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL + 'search';
    return axios.get(url!, {
      cancelToken: this.source.token,
      params: {
        query: message,
        accountId: accountId,
        contactNo: contactNo,
      }
    });
  };

  verifyAddress = (message: string, city: string, region: string) => {
    const url = process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL + 'validate-address';
    return axios.get(url!, {
      cancelToken: this.source.token,
      params: {
        address: message,
        city,
        region
      }
    });
  };

  postIssue = (org_id: number, intentId: number, intent: string, from: string, dialogueHistory: object[], slots: any, createOtherTypeRequest = false) => {
    const url = process.env.REACT_APP_CITIBOT_BASE_BACKEND_URL + 'issues?apikey=' + process.env.REACT_APP_CITIBOT_BACKEND_API_KEY;

    if (Object.keys(slots).includes('phone_number')) {
      from = slots.phone_number;
    }

    if (createOtherTypeRequest) {
      console.log('postIssue createOtherTypeRequest =>', createOtherTypeRequest)
      slots.message = getDynamicMessage(
        REPORT_ISSUE_MESSAGE,
        formatIntent(intent, slots),
        slots['address'] ? slots['address'] : null
      );
      delete slots.propertyType;
      intent = MESSAGE_INTENT;
      delete slots.issueType;
    }

    return axios.post(url!, {
        'organization_id': org_id,
        'intent_id': intentId,
        'intent': intent,
        'from': from,
        'dialog_history': dialogueHistory,
        'slots': slots,
        'source': 'web'
      }, {
        cancelToken: this.source.token,
      }
    );
  };

  translateText = async(text: string, targetLanguageCode: string): Promise<string> => {
    try {
      const url = process.env.REACT_APP_CITIBOT_TRANSLATOR_SERVICE_URL + '/translate-text';
      const res = await axios.post(url!, {
        text,
        targetLanguageCode
      }, {
        cancelToken: this.source.token,
      }
      );
      if (res.status === 200) {
        return res.data.translatedText as string
      } else {
        return text
      }
    } catch(err) {
      console.error('error while translateText service => ', err)
      return text
    }    
  };
  translateTextScout = async(inputText: string, languageCode: string): Promise<string> => {
    try {
      const url = process.env.REACT_APP_CITIBOT_SCOUT_TRANSLATE_URL;
      const res = await axios.post(url!, {
        inputText,
        languageCode
      }, {
        cancelToken: this.source.token,
      }
      );
      if (res.status === 200) {
        return res.data.translatedText as string
      } else {
        return inputText
      }
    } catch(err) {
      console.error('error while translateScout service => ', err)
      return inputText
    }    
  };
  getTranslateTextCode = async(text: string, targetLanguageCode: string): Promise<string> => {
    try {
      const url = process.env.REACT_APP_CITIBOT_TRANSLATOR_SERVICE_URL + '/translate-text';
      const res = await axios.post(url!, {
        text,
        targetLanguageCode
      }, {
        cancelToken: this.source.token,
      }
      );
      if (res.status === 200) {
        return res.data.sourceLanguageCode as string
      } else {
        return text
      }
    } catch(err) {
      console.error('error while translateText service => ', err)
      return text
    }    
  };
  cancelRequests = () => {
    this.source.cancel();
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
  };


}

export const services = Services.getInstance();
