import React from 'react';
import { Divider, FormControl, IconButton, Input } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import classes from './message-box.module.css';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

interface MessageBoxProps {
  themeColor: string;
  isMobileDevice: boolean;
  questionNumber: number;
  labelText: {
    type: string,
    meta: object
  } | string;
  onSend: (value: string) => void;
  onChange: (value: string) => void;
  loader: boolean;
  disableMessageButton: boolean;
  messageBoxHandler: () => void;
  showMessageButton: boolean;
  removeMessageButton: boolean;
}

export const MessageBox: React.FC<MessageBoxProps> = (props: MessageBoxProps) => {
  const [currentValue, setCurrentValue] = React.useState<string>('');
  const [showEmojiPicker, setShowEmojiPicker] = React.useState<boolean>(false);

  var bool_divide: boolean = true;

  // const numberInput: boolean = props.questionNumber === -2 ? props.isMobileDevice : false;
  const numberInput: boolean = false;

  let input = (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        props.onSend(currentValue.trim());
        setCurrentValue('');
      }}>
      <Input className={classes.textArea}
             type={numberInput ? 'number' : 'text'}
             placeholder={'Start typing...'} disableUnderline={true}
             value={currentValue}
             autoFocus={!props.isMobileDevice}
             onFocus={() => {
               setShowEmojiPicker(false);
             }}
             onChange={(event) => _handleChange(event.target.value)}
      />
      {/*<div className={classes.center}>*/}
      {/*    <button className={props.disableMessageButton ? classes.deactiveMessageButton : classes.messageButton}*/}
      {/*            disabled={props.disableMessageButton}>Message</button>*/}
      {/*</div>*/}
    </form>
  );

  return (
    <>
      {bool_divide ? (<Divider variant="middle"/>) : null}
      <div className={classes.messageBoxContainer}>
        <div className={classes.messageBoxRoot}>
          <FormControl className={classes.formControl}>
            {input}
          </FormControl>
          <IconButton style={{color: props.themeColor}} className={classes.sendButton} onClick={(e) => {
            setShowEmojiPicker(!showEmojiPicker);
          }}>
            <SentimentSatisfiedOutlinedIcon style={{color: props.themeColor}} fontSize="large"
                                            className={classes.sendButton}/>
          </IconButton>

          <IconButton style={{color: props.themeColor}} className={classes.sendButton} disabled={!currentValue}
                      onClick={(e) => {
                        props.onSend(currentValue);
                        setCurrentValue('');
                      }}>
            <Send style={{color: props.themeColor}} fontSize="large" className={classes.sendButton}/>
          </IconButton>
        </div>
        <div className={props.showMessageButton ? classes.center : classes.hideMessageButton}>
          <button
            className={props.disableMessageButton ? classes.deactiveMessageButton : classes.messageButton}
            style={{background: props.disableMessageButton ? 'lightgray' : props.themeColor, display: props.removeMessageButton ? 'none' : 'block'}} 
            disabled={props.disableMessageButton}
            onClick={props.messageBoxHandler}>
            Message
          </button>
        </div>
      </div>
      {
        // showEmojiPicker ?
        <span className={classes.emojiPicker} style={{visibility: showEmojiPicker ? 'visible' : 'hidden'}}>
                        <Picker onSelect={addEmoji}
                                native={true}
                                emojiSize={20}
                                showPreview={false}
                                showSkinTones={false}
                        />
                    </span>
        // :
        // null
      }
      {showEmojiPicker ? adjustEmojiPickerHeight() : null}
    </>
  );

  function addEmoji(e: any) {
    let emoji = e.native;
    const value = currentValue + emoji;
    setCurrentValue(value);
  }

  function _handleChange(value: string) {
    setCurrentValue(value);
    _sendValue();
  }

  function _sendValue() {
    props.onChange(currentValue);
  }

  function adjustEmojiPickerHeight() {
    const media: MediaQueryList = window.matchMedia('(max-height: 450px)');
    const emojiScrollArea: any = document.getElementsByClassName('emoji-mart-scroll')[0];
    if (media.matches) {
      emojiScrollArea.style.height = '170px';
    } else {
      emojiScrollArea.style.height = '270px';
    }
  }
};

