export const surveyMessages = [
  {
    title: 'How was the citibot chat service?',
    type: 'input',
    meta: 'type your answer'
  },
  {
    title: 'Are you satisfied with the product?',
    type: 'input',
    meta: 'type your answer'
  }
];
