import React, { useCallback, useEffect, useState } from 'react';
import {Box, Button, IconButton} from '@material-ui/core';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
// @ts-ignore
import Speech from 'speak-tts';
import { services } from '../../../Services/services';
import { joinStrings, splitStrings } from '../../../utils/string.util';

interface BinaryInputBuilderProps {
    option1: string,
    option2: string,
    onClick: Function,
    sourceLanguageCode: string,
    appLanguageCode: string
}

const BinaryInputBuilder: React.FC<BinaryInputBuilderProps> = (props: BinaryInputBuilderProps) => {
    const { sourceLanguageCode, appLanguageCode, option1, option2 } = props
    const [options, setOptions] = useState([option1, option2])
    const [speechReady, setSpeechReady]=React.useState(false);
    const speech = new Speech();
    speech.init({
        'volume': 1,
        'lang': `${sourceLanguageCode === appLanguageCode ? appLanguageCode : sourceLanguageCode}-US`,
        'rate': 1,
        'pitch': 1,
        'voice':'Google UK Female',
        'splitSentences': true,}).then((data:any) => {
        setSpeechReady(true);
    }).catch((e:any) => {
        setSpeechReady(false);
    });

    const speak=(data:string)=>{
        if(!speech.speaking()) {
            speech.speak({
                text: data,
            }).then(() => {
                console.log("Success !")
            }).catch((e: any) => {
                console.error("An error occurred :", e)
            });
        }
        else{
            stop();
        }
    };

    const stop=()=>{
        speech.cancel();
        console.log(speechReady);
    };
    const translateOptions = useCallback(async (choices: string[]) => {
        try {
            const translatedResponse = await services.translateText(joinStrings(choices), sourceLanguageCode)
            const newOptions = splitStrings(translatedResponse)
            setOptions(newOptions)
        } catch(err) {
            console.error('BinaryInputBuilder error while translating options =>',err)
        }
    }, [ setOptions, sourceLanguageCode])
    useEffect(() => {
        if (sourceLanguageCode !== appLanguageCode) {
            translateOptions([option1, option2])
        } else if (option1 !== options[0] || option2 !== options[1]) {
            setOptions([option1, option2])
        }
    }, [sourceLanguageCode, appLanguageCode, translateOptions, option1, option2, options, setOptions]);

    return <Box display={'flex'}>
        <Box m={2}>
            <Button style={{color: 'white', background: 'green'}} variant={'contained'}
                    onClick={() => props.onClick(options[0])}>
                {options[0]}
            </Button>{(speech.hasBrowserSupport())? <IconButton onClick={()=>{speak(options[0])}} ><VolumeUpIcon style={{ color: 'green' }}/></IconButton>:null}
        </Box>
        <Box m={2}>
            <Button style={{color: 'white', background: 'red'}} variant={'contained'}
                    onClick={() => props.onClick(options[1])}>
                {options[1]}
            </Button>{(speech.hasBrowserSupport())? <IconButton onClick={()=>{speak(options[1])}} ><VolumeUpIcon style={{ color: 'red' }} /></IconButton>:null}
        </Box>
    </Box>;
};

export default BinaryInputBuilder;
