import React from 'react';
import { MessageCard } from '../message-card/MessageCard';
import { MultiValuedFields } from '../MultiValuedFields/multi-valued-field';
import PreLoader from '../PreLoader/pre-loader';
import classes from './messagelist.module.css';
import { Box } from '@material-ui/core';


interface MessageListProps {
  themeColor: string;
  messages: { title: string, isBot: number, time: string }[] | null;
  labelText: {
    type: string,
    meta: object
  } | string;
  onSend: (value: string) => void;
  onChange: (value: string) => void;
  loader: boolean;
  link: boolean;
  haslinks: boolean;
  logo_url: any;
  message_url: string,
  issueAddress: string
  searchResponse: { title: string, link: string }[];
  searchResponseList: any;
  currentIntent: string;
  currentCity: string;
  finalLink: string;
  sourceLanguageCode: string;
  appLanguageCode: string;
  city: string
}

export const MessageList: React.FC<MessageListProps> = (props: MessageListProps) => {
  const { sourceLanguageCode, appLanguageCode } = props
  return (
    props.messages ?
      <div style={{padding: '0', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
        {props.messages.map((
          message: { title: string, isBot: number, time: string }, index: number
        ) => <MessageCard
          themeColor={props.themeColor}
          key={message.title + index}
          isMine={!(!!(message.isBot))}
          isFirstBotMessage={index === 0}
          message_url={props.message_url}
          haslinks={props.haslinks}
          searchResponse={props.searchResponseList[index]}
          message={message.title}
          messageTime={message.time}
          loader={props.loader}
          link={props.link}
          logo_url={props.logo_url}
          issueAddress={props.issueAddress}
          currentIntent={props.currentIntent}
          currentCity={props.currentCity}
          finalLink={props.finalLink}
          sourceLanguageCode={sourceLanguageCode}
          appLanguageCode={appLanguageCode}
          city={props.city}
        />)
        }
        <div>
          {props.loader ? <Box display="flex" flexDirection={'row'} alignItems={'center'}
                               width={'100%'}><img className={classes.iconBot} src={props.logo_url}
                                                   alt="citibot webchat"/><PreLoader/></Box> : null}
          <MultiValuedFields labelText={props.labelText} onSend={props.onSend} onChange={props.onChange} sourceLanguageCode={sourceLanguageCode} appLanguageCode={appLanguageCode}/>
        </div>
      </div> : <Box display="flex" flexDirection={'row'} alignItems={'center'}
                    width={'100%'}><img className={classes.iconBot} src={props.logo_url}
                                        alt="citibot webchat"/><PreLoader/></Box>
  );
};
