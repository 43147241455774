import React from 'react';
import classes from './multivalued-fields.module.css';
import { FormControl, IconButton } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import BinaryInputBuilder from './ElementBuilders/BinaryInputBuilder';
import ChoiceInputBuilder from './ElementBuilders/ChoiceInputBuilder';

interface MultiValuedFieldsProps {
  labelText: {
    type: string,
    meta: object
  } | string;
  onSend: (value: string) => void;
  onChange: (value: string) => void;
  sourceLanguageCode: string;
  appLanguageCode: string;
}

enum FieldTypes {
  BINARY = 'binary',
  MULTI_CHOICE = 'multi_choice',
  SINGLE_CHOICE = 'single_choice'
}

export const MultiValuedFields: React.FC<MultiValuedFieldsProps> = (props: MultiValuedFieldsProps) => {
  const { sourceLanguageCode, appLanguageCode } = props

  const [currentValue, setCurrentValue] = React.useState<string>('');
  const [checkedValuesList, setCheckedValuesList] = React.useState<string[]>([]);

  let input;

  if (typeof props.labelText === 'object') {
    const type = props.labelText.type;
    let meta: any = props.labelText.meta;
    switch (type) {
      case FieldTypes.BINARY:
        input = (
          <div className={classes.choiceFields}>
            <BinaryInputBuilder option1={meta.accept_text}
                                option2={meta.reject_text}
                                onClick={_handleMetaSend}
                                sourceLanguageCode={sourceLanguageCode}
                                appLanguageCode={appLanguageCode}
            />
          </div>
        );
        break;
      case FieldTypes.SINGLE_CHOICE:
        input = (
          <div className={classes.choiceFields}
            // style={{height: "4rem", width: "100%", overflow: "auto"}}
          >
            <ChoiceInputBuilder
              choices={meta.choices}
              checkedChoices={checkedValuesList}
              multi={false}
              onChecked={_handleOnChecked}
              sourceLanguageCode={sourceLanguageCode}
              appLanguageCode={appLanguageCode}
            />
          </div>);
        break;
      case FieldTypes.MULTI_CHOICE:
        input = (
          <div className={classes.choiceFields}>
            <ChoiceInputBuilder
              choices={meta.choices}
              checkedChoices={checkedValuesList}
              multi={true}
              onChecked={_handleOnChecked}
              sourceLanguageCode={sourceLanguageCode}
              appLanguageCode={appLanguageCode}
            />
          </div>);
        break;
    }
  }

  return (
    <>
      {/*{bool_divide ? (<Divider variant="middle" />) : null}*/}
      <div style={{'display': 'flex'}}>
        <FormControl className={classes.formControl}>
          {typeof props.labelText === 'object' ? input : null}
        </FormControl>
        {(typeof props.labelText === 'object' && props.labelText.type === FieldTypes.MULTI_CHOICE) ?

          <IconButton className={classes.sendButton} disabled={!currentValue} onClick={(e) => {
            props.onSend(currentValue);
            setCheckedValuesList([]);
            setCurrentValue('');
          }}>
            <Send fontSize="large"/>
          </IconButton> : null
        }
      </div>
    </>
  );

  function _handleOnChecked(value: string, multi: boolean) {
    console.log('MultiValuedFields _handleOnChecked value =>', value, ' multi =>',multi)
    if (multi) {
      if (!checkedValuesList.includes(value)) {
        checkedValuesList.push(value);
      } else {
        checkedValuesList.splice(checkedValuesList.indexOf(value), 1);
      }

    } else {
      checkedValuesList.splice(0, checkedValuesList.length);
      props.onSend(value);
    }

    setCurrentValue(checkedValuesList.toString());
  }

  function _handleMetaSend(value: string) {
    props.onSend(value);
  }
};
