import React, { useCallback, useEffect, useState } from 'react';
import {Box, Chip, IconButton} from '@material-ui/core';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
// @ts-ignore
import Speech from 'speak-tts';
import { services } from '../../../Services/services';
import { joinStrings, splitStrings } from '../../../utils/string.util';
import { arrayEqual } from '../../../utils/helper.util';

interface ChoiceInputBuilderProps {
    choices: string[]
    checkedChoices: string[]
    multi: boolean
    onChecked: Function
    sourceLanguageCode: string
    appLanguageCode: string
}

const GRAY_BLURB_COLOR = "#3A3A3C"
const ChoiceInputBuilder: React.FC<ChoiceInputBuilderProps> = (props: ChoiceInputBuilderProps) => {
    const { sourceLanguageCode, appLanguageCode, choices } = props
    const [options, setOptions] = useState(choices);

    const [speechReady, setSpeechReady]=React.useState(false);
    const speech = new Speech();
    speech.init({
        'volume': 1,
        'lang': `${sourceLanguageCode === appLanguageCode ? appLanguageCode : sourceLanguageCode}-US`,
        'rate': 1,
        'pitch': 1,
        'voice':'Google UK Female',
        'splitSentences': true,}).then((data:any) => {
        setSpeechReady(true);
    }).catch((e:any) => {
        setSpeechReady(false);
    });
    const translateOptions = useCallback(async (choices: string[]) => {
        try {
            const translatedResponse = await services.translateText(joinStrings(choices), sourceLanguageCode)
            const newOptions = splitStrings(translatedResponse)
            setOptions(newOptions)
        } catch(err) {
            console.error('ChoiceInputBuilder error while translating options =>',err)
        }
    }, [ setOptions, sourceLanguageCode])
    useEffect(() => {
        if (sourceLanguageCode !== appLanguageCode) {
            translateOptions(choices)
        } else if (options.length !== choices.length) {
            setOptions(choices);
        } else {
            if (!arrayEqual(choices, options)) {
                setOptions(choices);
            }
        }
    }, [sourceLanguageCode, appLanguageCode, translateOptions, choices, options, setOptions]);

    const speak=(data:string)=>{
        if(!speech.speaking()) {
            speech.speak({
                text: data,
            }).then(() => {
                console.log("Success !")
            }).catch((e: any) => {
                console.error("An error occurred :", e)
            });
        }
        else{
            stop();
        }
    };

    const stop=()=>{
        speech.cancel();
        console.log(speechReady);
    };

    return (
        <Box display='flex' flexDirection='column' justifyContent="center">
            {/*{props.choices.length ? <p className={classes.responses}>RESPONSES</p> : <></>}*/}
            <Box justifyContent="center">{options.map((choice, index) =>
                <Box justifyContent="center" key={index}>
                    <Chip
                        label={choice}
                        clickable={true}
                        style={{margin: "5px", minWidth:"250px",minHeight:"40px", color: GRAY_BLURB_COLOR, borderColor: GRAY_BLURB_COLOR}}
                        variant={props.checkedChoices.includes(choice) ? "default" : "outlined" }
                        onClick={() => {
                            props.onChecked(choice.trim(), props.multi);
                        }}
                    />
                    {(speech.hasBrowserSupport())? <IconButton onClick={()=>{speak(choice)}} ><VolumeUpIcon style={{color: GRAY_BLURB_COLOR}}/></IconButton>:null}
                </Box>)}</Box>
        </Box>
    )
};

export default ChoiceInputBuilder;
