import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#48c3e3'
    },
    secondary: {
      main: '#fafafa'
    }
  },
  props: {
    MuiIconButton: {
      color: 'primary'
    }
  }
});

