export const WELCOME_MESSAGE = 'Hello! I am Citibot! \n' +
  'Please type a question or send us a service request like a pothole, lost dog, or missed trash pickup.  If you need to connect with staff, click the “MESSAGE” button and let us know how we can help you.';
/// "Please provide your mobile number so we can respond to you if needed.";

export const REPHRASE_PROBLEM_MESSAGE = 'Sorry, I\'m having trouble finding an answer for you. Can you try to rephrase the question? Or click the \'MESSAGE\' button to send your question to a staff member so we can follow up with you.';

export const OAKLAND_REPHRASE_PROBLEM_MESSAGE = 'Sorry, I\'m having trouble finding an answer. Please call 311 within City limits or (510) 615-5566 during our business hours of 8am and 4:30pm, Monday through Friday.';

export const ASK_ISSUE_MESSAGE = 'Thank you!  Please type your question, or report your service request, or send us a message by clicking the “MESSAGE” button and let us know how we can help you.';

export const ASK_NEW_ISSUE_MESSAGE = 'Please type your question, or report your service request, or send us a message by clicking the “MESSAGE” button and sending to us.';

export const COLORADO_ASK_NEW_MESSAGE = "Please type your question, or report your service request, or send us a message by clicking the “MESSAGE” button to create a GoCOS request";

export const SEARCH_QUESTION = 'What are you looking for?';

export const BALT_ADDRESS_VALIDATION_ERROR  = `The location you entered is outside the City's jurisdiction for this service type. - 35`;

export const ROSEVILLE_ADDRESS_VALIDATION_ERROR = 'The request selected cannot be reported at the specified location.';

export const ADDRESS_VALIDATION_ERROR = 'This type of service request cannot be posted against that address';

export const CITIBOT_ADDRESS_ERROR_MSG =  `Sorry, the issue you're trying to report is outside of our service area. Your request cannot be submitted.`;

export const CANNOT_REGISTER_PROBLEM = 'I am sorry that I didn’t understand that.  Please send us a message by clicking the “MESSAGE” button and sending it to us.  And we will respond to you!';

export const REQUEST_SUBMITTED_MESSAGE = 'Thank you I’ve reported the issue. A customer service representative will get back to you as soon as possible.';

export const OAKLAND_REQUEST_SUBMITTED_MESSAGE = 'Thank you for contacting Oak311 chat. A service request tracking number will be sent to the email address provided.';

export const REQUEST_NOT_SUBMITTED_MESSAGE = 'Sorry I could not submit your request, Can you please describe your issue again or click the “MESSAGE” button and then send us a message so our staff can help you?  Thanks!';

export const ISSUE_HELP_MESSAGE = 'Thank you!  Please type your question, or report your service request, or send us a message by clicking the “MESSAGE” button and sending to us.';

export const CONFIRMATION_MESSAGE = 'It looks like you are trying to report an issue, is that correct? Please type \'Cancel\' at anytime to start over.';

export const CONFIRMATION_MESSAGE_MESSAGE_INTENT = 'It looks like you would like to send a message, is that correct? Please type \'Cancel\' at anytime to start over.';

export const CONFIRMATION_REJECTED_MESSAGE = 'No problem, what can I help you with today?  If you are having trouble you can always click the message button to send a message.';

export const SUBMIT_SR_CONFIRMATION_MESSAGE = 'You\'re reporting an issue, would you like me to submit this request?';

export const OAKLAND_SUBMIT_SR_CONFIRMATION_MESSAGE = 'You\'ve reported an issue. Is that correct?';

export const REPORT_ISSUE_MESSAGE = 'want to report an issue';

export const CITIBOT_SUCCESS_MSG = 'success';

export const BALT_RODENT_QUESTION = 'Are rodents visible?';

export const BALT_SIGNAL_TYPE_QUESTION = 'What type of signal?';

export const BALT_WATER_COMING_FROM_QUESTION = 'Where is the water coming from?';

export const BALT_RAINING_QUESTION = 'Is it raining in your area?';

export const BALT_ILLEGALY_PARKED_QUESTION = 'How is the vehicle illegally parked?';

export const BALT_VEHICLE_LOCATION = 'Where is the vehicle located was at sidewalk or alley?';

export const BALT_STREET_LIGHT_QUESTION = 'Where is the light located was at front or rear?';

export const BALT_GRASS_MOWING_HCDSPROP_INTENT = 'baltimorebot_dep_grass_mowing_HCDSPROP_intent';

export const BALT_TRAFFIC_SIGNAL_TRSIWATI_INTENT = 'baltimorebot_dep_traffic_signal_TRSIWATI_intent';

export const CS_SUCCESS_MSG = 'Thank you for submitting your issue to Colorado Springs! Use this tracking number #ID to stay on top of your issue. View or submit issues here: https://iframe.publicstuff.com/#?client_id=1000158';

export const SUBMIT_MESSAGE_CONFIRMATION_MESSAGE = 'Ok I\'ve got that message ready to send! Would you like me to send it?';

export const START_NEW_CONVERSATION_MESSAGE = 'Is there anything else that I can help you with?';

export const NEW_CONVERSATION_MESSAGE = 'Is there anything else that I can help you with?';

export const VISIT_LINK_MESSAGE = 'Sorry, I\'m having trouble finding an answer for you. Can you try to rephrase the question? Or click the \'MESSAGE\' button to send your question to a staff member so we can follow up with you.';

export const OAKLAND_VISIT_LINK_MESSAGE = 'Sorry, I\'m having trouble finding an answer. Please call 311 within City limits or (510) 615-5566 during our business hours of 8am and 4:30pm, Monday through Friday.';

export const SEARCH_RESULT_READY_MESSAGE = 'Here are our top results to your question. I hope it helps! And please “MESSAGE” us if it doesn\'t so we can get your answer for you!';

export const CONTINUE_CONVERSATION_MESSAGE = 'Is there anything else that I can help you with?';

export const END_CONVERSATION_MESSAGE = 'Thank you for using Citibot!  Have a nice day!';

export const END_CONVERSATION_THANKS_MESSAGE = 'Thank you for using our platform. Have a nice day!';

export const CONTINUE_CONVERSATION_GREETING = 'Hello! I am Citibot! \n' +
  'Please type a question or send us a service request like a pothole, lost dog, or missed trash pickup.  If you need to connect with staff, just click “MESSAGE” button and let us know how we can help you.';

// export const HELLO_INTENT_RESPONSE = "Hello! Hope you are having a great day today. \n" +
//     "Please type a question or send us a service request like a pothole, lost dog, or missed trash pickup.  If you need to connect with staff, just click “MESSAGE” button and let us know how we can help you.";

export const HELLO_INTENT_RESPONSE = '\nPlease type a question or send us a service request like a pothole, lost dog, or missed trash pickup.  If you need to connect with staff, just click “MESSAGE” button and let us know how we can help you.';

export const SEARCH_CARD_MESSAGE = 'If this info doesn\'t help you, please click the “MESSAGE” button and send us your question. We will get back to you!';

export const COLORADO_SEARCH_CARD_MESSAGE = "If this info doesn't help you, please click the “MESSAGE” button to create a GoCOS request."

export const ENTER_VALID_PHONE_NUMBER = 'Please enter a valid phone number.';

export const ENTER_VALID_EMAIL = 'Please enter a valid email address.';

export const ENTER_VALID_ADDRESS = '{address} is not a valid address, please try entering the address again and make sure everything is spelled correctly. If you did not include a city & state, please do so.';

export const ENTER_VALID_EMAIL_ADDRESS = 'Please enter a valid email address.';

export const DONT_PROVIDE_EMAIL = '\nIf you don\'t want to enter one please type "anonymous"';

export const TAKE_SURVEY_MESSAGE = 'Please take this survey to help us improve. \n' +
  'If you don\'t want to take the survey please type "no" else type "yes"';

export const CANCEL = 'cancel';

export const THANKYOU = 'thankyou';

export const THANK_YOU = 'thank you';

export const THANKS = 'thanks';

export const NEVER_MIND = 'never mind';

export const NEVERMIND = 'nevermind';

export const START_OVER = 'start over';

export const QUIT = 'quit';

export const STOP = 'stop';

export const FORGET_IT = 'forget it';

export const NO_THANKYOU = 'no thankyou';

export const NO_THANK_YOU = 'no thank you';

export const NO_THANKS = 'no thanks';

export const NOTHANKS = 'nothanks';

export const NOTHANKYOU = 'nothankyou';

export const NO = 'no';

export const HELLO_INTENT = 'hellointent';

export const HELLO_INTENT_PHRASE = 'hellointentent';

export const THANK_YOU_INTENT = 'thank_you_intent';

export const COVID_VACCINE_INTENT = 'cb_covid_vaccine_intent';

export const SEARCH_INTENT = 'cb_search_dispatch_intent';

export const MESSAGE_INTENT = 'cb_message_intent';

export const WASTE_CONTAIER_INTENT = 'cb_waste_container_intent';

export const BALTIMORE_ILLEGAL_DUMPING_INTENT = 'baltimorebot_illegal_dumping_intent';

export const BALTIMORE_NOISE_INTENT = 'baltimorebot_dep_noise_intent_bclbllc';

export const BALT_PROPERTY_CAUSING_NOISE = 'What type of property is causing the noise?';

export const BALT_RESTAURANT_OR_BAR_QUES = 'Is this a restaurant or bar?';

export const BALT_TYPE_OF_NOISE = 'What type of noise ?';

export const ROSEVILLE_RESIDENTIAL_PARKING_PREMIT_INTENT = 'roseville_residential_parking_permit_intent';

export const ROSEVILLE_HOME_OWNER_QUES = 'Are you the home owner ?';

export const WASTE_SERVICE_INTENT = 'cb_waste_service_intent';

export const WASTE_PICKUP_INTENT = 'cb_waste_pickup_intent';

export const NOLA_TREE_INTENT = 'nola_tree_intent';

export const NOLA_PEST_INTENT = 'nola_pest_control';

export const NOLA_MAYOR_INTENT = 'nola_mayor_intent';

export const WEHO_SCOOTER_INTENT = 'weho_scooter_intent';

export const NOLA_FLOOD_INTENT = 'nola_flood_intent';

export const OAKLAND_YARD_UPKEEP_INTENT = 'cb_oak_yard_upkeep_intent';

export const OAKLAND_BROKEN_SIDEWALK_INTENT = 'cb_oak_broken_sidewalk_intent';

export const OAKLAND_TALLGRASS_INTENT = 'cb_oak_tallgrass_intent';

export const OAKLAND_POTHOLE_INTENT = 'cb_oak_pothole_intent';

export const OAKLAND_ABANDONED_VEHICLE_INTENT = 'cb_oak_abandoned_vehicle_intent';

export const PARKING_PERMIT_INTENT = 'cb_parking_permit_intent';

export const COVID_NON_COMPLIANCE_INTENT = 'cb_covid_non_compliance_intent';

export const HURRICANE_IDA_INTENT = 'hurricane_ida_intent';

export const YES = 'yes';

export const SURE = 'sure';

export const YES_SURE = 'yes sure';

export const DEFINITELY = 'definitely';

export const YA_SURE = 'ya sure';

export const WEBCHAT_HEADER = 'Citibot Web Chat';

export const NEW_ORLEANS = 'new orleans';

export const ROCHESTER = 'rochester';

export const ROCHESTER_IS_MN_PLATE_SLOT = 'rocIsMnPlate';

export const ROCHESTER_WHAT_STATE_SLOT = 'whatState';

export const OAKLAND = 'oakland';

export const GENERIC_LAG_MESSAGE_RES = "Please wait a couple more seconds, I'm finding an answer for you.";

export const NOLA_WASTE_CONTAINER_QUESTION = 'Would you like to repair, replace, return or order a cart?';

export const MORE_THAN_FOUR_UNITS = 'Does your residence consist of more than 4 units?';

export const MORE_THAN_FOUR_UNITS_IN_PROPERTY = 'Does the property consist of more than 4 units?';

export const NOLA_WASTE_CONTAINER_ORDER_RESPONSE = 'For properties consisting of more than 4 units, please contact your property manager or landlord for assistance.';

export const CONTACT_311_MESSAGE = 'This is an emergency. Please call 3-1-1 or (504) 658-2299 immediately.';

export const TREE_ON_POWER_LINE = 'Please answer yes or no.\n\nDo any of these describe your problem: tree is on top of' +
  ' personal property or power lines, broken branches, a tree or branch is blocking' +
  ' a driveway/street/sidewalk/traffic signal/stop sign, tree down';

export const TREE_BLOCKING_FOLLOWING = 'Is the tree blocking any of the following? (driveway, sidewalk, or street, traffic signal, stop sign)';

export const NOLA_TREE_SERVICE_TYPE = 'Is your request for park maintenance/clean up, tree service or stump removal?';

export const CONTACT_911_FOR_FLOODING = 'Please call 911 to report the street flooding.';

export const IS_STREET_FLOODED = 'Is the street flooded?';

export const BALT_IS_SEE_DUMPING = 'Did you see the dumping?'

export const BALT_IS_SEE_INDIVIDUAL = 'Did you see the individuals involved?';

export const BALT_IS_VEHICLE_INVOLVED = 'Did you see a vehicle involved?';

export const BALT_LIGHT_PROB = 'What is the problem with the light?';

export const BALT_POTHOLE_LOC = 'Where is the pothole located?';

export const BALT_IS_ANYONE_INJURED_QUES = 'Is anyone injured?';

export const BALT_TRASH_CAN_REQ_QUES = 'What is the problem with the public trash can?';

export const BALT_TRASH_CAN_LOC_QUES = 'Where is the can located?';

export const BALT_PARK_TRASH_CAN_CONCERN_QUES = 'What is the can concern?';

export const BALT_PARK_MAINTENANCE_QUES = 'What is the park maintenance issue?';

export const BALT_PLANNED_ACTIVITY_SCHEDULED = 'Is there a planned event or activity scheduled?';

export const BALT_PERMITTED_EVENT = 'Is this a permitted event or activity?';

export const BALT_WHATS_NEED_ATTENTION_QUES = 'What needs attention?';

export const BALT_TYPE_OF_TRASH_QUES = 'Type of trash?';

export const BALT_TRASH_FROM_VEHICLE = 'Was the trash dumped from a vehicle, small hauler, or contractor?';

export const BALT_HAZARDOUS_CONDITION_QUES = 'Do you consider this hazardous condition?';

export const BALT_POTHOLE_ISSUE = 'Is the problem due to a water meter, valve, manhole or steel plate?';

export const BALT_VEHICLE_MAKER = 'Vehicle maker?';

export const IS_PRIVATE_PROPERTY = 'Please answer Yes or No. Is the vehicle located on private property?';

export const OAKLAND_ABANDONED_VEHICLE_MESSAGE = 'Please call Vehicle Abatement Detail at 510-777-8538 for further assistance.';

export const EMAIL_ADDRESS_IS_REQUIRED = 'A valid email address is required to create request.';

export const ADDRESS_IS_REQUIRED = 'A valid address is required to create request.';

export const PHONE_IS_REQUIRED = 'A valid phone number is required to create request.';

export const ANSWER_IS_REQUIRED = 'Ansewer to this question is required.';

export const CONTACT_MAYOR = 'To contact the mayor, please follow the link:';

export const EMERGENCY_INTENT = 'cb_emergency_intent';

export const ANIMAL_SERVICE_INTENT = 'cb_iss_animal_services_intent';

export const EMERGENCY_CONTACT_911 = 'For emergencies, please call 911';

export const IS_BULK_ITEM = 'Is this a bulk item?';

export const WASTE_CONTAINER_REPORT_SUBMITTED = 'Thank you! Your request has been submitted.' +
  ' In addition, please call 3-1-1 or (504) 658-2299 as there may be fees associated with this request.';

export const WASTE_CONTAINER_REQUEST_SUBMITTED = 'Thank you, your request has been submitted.';


export const COVID_LARGE_GATHERING = 'To report large gatherings, please call the non-emergency line at 504-821-2222.';

export const IS_LARGE_GATHERING_SLOT = 'isLargeGathering';

export const WEST_HOLLYWOOD = 'west hollywood';

export const ISSUE_HELP_MESSAGE_COMPACT = 'Please type your question or send us a message by clicking the “MESSAGE” button below.';

export const WEHO_SCOOTER_INTENT_MESSAGE = 'E-scooter and e-bike pilot program information can be found on our site below \n\nTo report an issue, please call (213) 247-7720.';

export const SLOT_IS_BULK = 'isBulk';

export const PUBLIC_PROPERTY_INTENT = 'cb_iss_public_property_intent';

export const SLOT_USER_MESSAGE = 'userMessage';

export const ROSEVILLE_SEARCH_RESULT_READY_RES_MSG = "Here are our top results to your question. I hope it helps! And please reach out if it doesn't so we can get your answer for you!";

export const OAKLAND_SEARCH_RESULT_READY_MESSAGE = 'Here are our top results to your question. I hope they help! \n' +
  'If they don’t, please call 311 within City limits or (510) 615-5566 during our business hours of 8am and 4:30pm, Monday through Friday.';

export const BALTIMORE = 'baltimore';

export const DENVER = 'denver';

export const FLORENCE = "florence";

export const ROSEVILLE = 'roseville';

export const ColoradoSprings = 'colorado springs';

export const BAKERSFIELD = 'bakersfield';

export const BAKERSFIELD_PROPERTY_VIOLATION_INTENT = 'bakersfield_property_violation_issue_intent';

export const ROSEVILLE_SEARCH_INTENT = 'roseville_search_dispatch_clone_intent';

export const PERRIS_SEARCH_INTENT = 'perris_search_dispatch_clone_intent';

export const COCOA_SEARCH_INTENT = 'cocoa_search_dispatch_clone_intent';

export const IOP_SEARCH_INTENT = 'iop_search_dispatch_clone_intent';
export const UC_SEARCH_INTENT = 'uc_search_dispatch_clone_intent';

export const LACSD_SEARCH_INTENT = 'lacsd_search_dispatch_clone_intent';
export const CC_SEARCH_INTENT = 'cc_search_dispatch_clone_intent';
export const BOERNE_SEARCH_INTENT='boerne_search_dispatch_clone_intent';
export const DENVER_SEARCH_INTENT = 'denver_search_clone_intent';

export const ENDING_PHRASES = [
  NO_THANKYOU,
  NO_THANK_YOU,
  NO_THANKS,
  NOTHANKS,
  NEVERMIND,
  NEVER_MIND,
  NO,THANKS,
  THANKYOU,
  THANK_YOU,
    'all good',
    's`all good',
    'all good here',
    'nothing else',
    'nope i’m good',
    "s’all good",
    "thanks, i'm good",
    "thanks, i’m good",
];

export const LEWISVILLE = "lewisville";

export const LEWISVILLE_VEHICLE_CONCERN_SLOT = "lewisVilleVehicleConcernType";

export const LEWISVILLE_UNIMPROVED_VEHICLE_SLOT = "lewisVilleParkingOnUnimprovedLocType";

export const LEWVILLE_POTHOLE_SLOT = "lewisvillePotholeConcernType";

export const LEWISVILLE_TRAFFIC_SIGNAL_CONCERN_SLOT = "lewisvilleSignalConcernType";

export const LEWISVILLE_TREE_FALLEN_SLOT = "isTreeFallenOnSideWalk";

export const LEWISVILLE_TRASH_CART_CONCERN_SLOT = "lewisvilleTrashCartConcernType";

export const LEWISVILLE_RECYCLE_LOC_SLOT = "lewisvilleRecycleLocationType";

export const LEWISVILLE_RECYCLE_ISSUE_SLOT = "lewisvilleResidentialLocationIssueType";

export const LEWISVILLE_TRASH_CART_LOC_SLOT = "lewiseVilleTrashCartLocationType";

export const LEWISVILLE_TRASH_CART_RESIDENTIAL_LOC_ISSUE_SLOT = "lewsvilleTrashCartResidentialLocationIssueType";
