const customSeparator = '\x1F'

export const joinStrings = (strArr:string[], separator = customSeparator) => {
    return strArr.reduce((acc,elem, index, arr) => {
        return acc + elem.replaceAll("@","@a").replaceAll("|","@p")+`${index !== arr.length -1 ? '|': ''}`
    }, '')
}


export const splitStrings = (str:string, separator = customSeparator) => {
    return str.split('|').map(elem => elem.replaceAll("@p","|").replaceAll("@a","@"))
}


// export const joinStrings = (arr:string[], separator = customSeparator) => {
//     return arr.join(separator)
// }


// export const splitStrings = (str:string, separator = customSeparator) => {
//     return str.split(separator)
// }