import {
  NOLA_FLOOD_INTENT,
  CS_SUCCESS_MSG,
  ENDING_PHRASES,
} from './messages';
import { handleBALTIntentSwitching } from './mappings';
import moment from 'moment';

export const getMessageTime = () => {
  let date: Date = new Date();

  let hours: number = date.getHours();
  let minutes: any = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const time: string = 'Delivered ' + hours + ':' + minutes + ' ' + ampm;
  // console.log(time);
  return time;
};

export const isYesUtterance = (message: string) => {
  const userResponse = message.toLowerCase();
  if (
    userResponse === 'yes' || userResponse === 'yep' || userResponse === 'si' || userResponse === 'y'
    || userResponse === 'yeah' || userResponse === 'sure' || userResponse === 'ok'
    || userResponse === 'okay' || userResponse === 'correct' || userResponse === 'that is correct'
  ) {
    return true;
  }
};

export const isNoUtteranceMessage = (message: string) => {
  const replyMessage = message.toLowerCase();

  return ENDING_PHRASES.includes(replyMessage);
};

export const isNoUtterance = (message: string) => {
  if (
    message === 'no' || message === 'nope' || message === 'n'
  ) {
    return true;
  }
};

export const formatIntent = (intent: string, slots: any, firstUserReply?: string) => {
  let issueIntent = undefined;

  if (slots && slots['issueType']) {
    issueIntent = slots['issueType'];
  } else if (slots && slots['propertyType']) {
    issueIntent = slots['propertyType'];
  } else {
    if (intent.toLowerCase() === 'fw_cave_in_intent') {
      if (slots && slots['cave_in_phrase']) {
        return slots['cave_in_phrase'];
      } else if (firstUserReply) {
        const reply = firstUserReply.toLowerCase();
        if (reply.includes('big hole') || reply.includes('bighole')) {
          return 'big hole';
        } else if (reply.includes('caving')) {
          return 'caving';
        } else if (reply.includes('sink hole') || reply.includes('sinkhole')) {
          return 'sinkhole';
        } else {
          return 'cave in';
        }
      } else {
        return 'cave in';
      }
    }

    if (intent.toLowerCase() === NOLA_FLOOD_INTENT) {
      if (slots && slots['isDitch'] && isYesUtterance(slots['isDitch'])) {
        return 'ditch';
      } else if (slots && slots['floodingType']) {
        return slots['floodingType'];
      } else if (firstUserReply) {
        const reply = firstUserReply.toLowerCase();
        if (reply.includes('flood')) {
          return 'flooding';
        } else if (reply.includes('culvert')) {
          return 'culvert';
        } else if (reply.includes('catch basin') || reply.includes('basin')) {
          return 'catch basin';
        } else {
          return 'flooding';
        }
      } else {
        return 'flooding';
      }
    }
    
    if(intent.toLowerCase() === 'montgomery_repair_trashcan_issue_intent'){
      return 'montgomery repair or missing trash can issue'
    }

    issueIntent = handleBALTIntentSwitching(intent);
    const intentToArr = issueIntent.split('_');

    let formattedIntent = intentToArr.filter((str) => {
      if (str !== 'cb' && str !== 'iss' && str !== 'intent') {
        return str;
      }
      return null;
    });

    issueIntent = formattedIntent.join(' ');
  }

  return issueIntent;
};

export const removeEmojisFromMessage = (message: string) => {
  const reg = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  return message.replace(reg, '');
};

export const getDynamicMessage = (message: string, issueIntent: string, address: string | null) => {
  let dynamicMessage = '';
  if (issueIntent.includes('oak')) {
    const issue = issueIntent.split('oak ');
    issueIntent = issue[1];
    if (address) {
      //custom message for cb_oak_yard_upkeep_intent and cb_oak_tallgrass_intent.
      if (issueIntent === 'yard upkeep') {
        dynamicMessage = 'You\'ve reported an issue at ' + address + '. Is that correct?';
      } else if (issueIntent === 'tallgrass') {
        dynamicMessage = 'You\'ve reported overgrowth at ' + address + '. Is that correct?';
      } else {
        dynamicMessage = message.replace('an issue',
          issueIntent + ' at ' + address);
      }
    } else {
      dynamicMessage = message.replace('an issue', issueIntent);
    }

    return dynamicMessage;
  }

    if (issueIntent.includes('roseville')) {
      issueIntent = issueIntent.replace('roseville', 'Roseville');
      dynamicMessage = message.replace('an issue',
        issueIntent + ' on ' + address);
    }

  if (issueIntent.includes('baltimorebot') || issueIntent.includes('baltimorebot_dep')) {
    issueIntent = issueIntent.replace('baltimorebot', '');
    issueIntent = issueIntent.replace('dep', '');
      dynamicMessage = message.replace('an issue',
        issueIntent + ' on ' + address);
  }

  if (issueIntent.includes('bakersfield')) {
    issueIntent = issueIntent.replace('bakersfield', '');
      dynamicMessage = message.replace('an issue',
        issueIntent + ' on ' + address);
  }

  if (issueIntent.includes('vestavia')) {
    issueIntent = issueIntent.replace('vestavia hills', '');
      dynamicMessage = message.replace('an issue',
        issueIntent + ' on ' + address);
  }

  if (issueIntent.includes('denver missed trash')) {
    issueIntent = issueIntent.replace("missed trash pickup" , "garbage collection");
    dynamicMessage = message.replace('an issue',
        issueIntent + ' on ' + address);
  }

  if (issueIntent.includes('roadkill')) {
    issueIntent = issueIntent.replace("roadkill" , "dead animal");
    dynamicMessage = message.replace('an issue',
        issueIntent + ' on ' + address);
  }

  if (issueIntent.includes('florence')) {
    issueIntent = issueIntent.replace('florence', '');
      dynamicMessage = message.replace('an issue',
        issueIntent + ' on ' + address);
  }

  if (address) {
    dynamicMessage = message.replace('an issue',
      issueIntent + ' on ' + address);
  } else {
    dynamicMessage = message.replace('an issue', issueIntent);
  }

  return dynamicMessage;
};

export const SuccessDynamicMessage = (id: string,successMsg:string) => {
  let message = successMsg;
  message = message.replace('ID', id);
  return message;
};


export const CSSucessDyanmicMessage = (id: string) => {
  let message = CS_SUCCESS_MSG;
  message = message.replace('ID', id);
  let searchResponse:{ title: string, link: string }[] = [];
  const urlPattern = /https?:\/\/[\S]*/g;
  const title = message.replace(urlPattern, '');
  const links = urlPattern.exec(message);
  if (links) {
    for (const link of links) {
      message = `${title}`;
      searchResponse = [
        {title: 'visit us here', link: link},
      ];
    }
  }
  return {
    message: message,
    searchResponse: searchResponse ?? []
  }
};

export const rephraseDyanmicMessage = (msg: string) => {
  let message = msg;
  let searchResponse:{ title: string, link: string }[] = [];
  const urlPattern = /https?:\/\/[\S]*/g;
  const title = message.replace(urlPattern, '');
  const links = urlPattern.exec(message);
  if (links) {
    message = `${title}`;
    for (const link of links) {
      searchResponse.push({title: 'visit us here', link: link})
    }
  } else {
    message = `${title}`
  }
  return {
    message: message,
    searchResponse: searchResponse ?? []
  }
};

export const handleDateLogicForBALT = (date: string) => {
  const currentDateTime = moment();
  const possibleFormats = ['DD-MM-YYYY HH:mm', 'YYYY-MM-DD HH:mm', 'DD/MM/YYYY HH:mm'];
  let inputMoment:any;
  for (let format of possibleFormats) {
    inputMoment = moment(date, format, true);
    if (inputMoment.isValid()) {
      break;
    }
  }
  if (
    inputMoment.isValid() &&
    inputMoment.year() === currentDateTime.year() &&
    inputMoment.isSameOrBefore(currentDateTime)
  ) {
    return true;
  } else {
    return false;
  }
}

export const getCovidSearchResponse = (responseMessage: string) => {
  const searchResponse = responseMessage.split('http');
  return [{title: searchResponse[0], link: 'http' + searchResponse[1]}];
};

export const getNOLAMayorRequestLink = (responseMessage: string) => {
  console.log('nola response mayor responseMessage => ',responseMessage)
  const searchResponse = responseMessage.split('http');
  return {
    title: searchResponse[0],
    searchResponse: [{title: 'contact Mayor', link: 'http' + searchResponse[1]}]
  };
};

export const getWeHoScooterIntentLink = () => {
  return [{
    title: 'visit us here',
    link: 'https://www.weho.org/city-government/city-departments/planning-and-development-services/long-range-planning/mobility-planning/dockless-mobility-pilot-program'
  }];
};


export const hasParkingPermitIntentFlowLinks = (slot: string, message: string) => {
  const yesUtterance: boolean | undefined = isYesUtterance(message.toLowerCase());

  let title = '';
  let links: { title: string, link: string }[] = [];

  if (slot === 'need_parking_permit' && !yesUtterance) {
    title = 'Please see our site to learn more about parking permits and how to apply';
    links = [
      {
        title: 'visit us here',
        link: 'https://www.weho.org/city-government/city-departments/facilities-and-recreation-services/parking-services/parking-permits'
      }
    ];
  } else if (slot === 'have_parking_permit' && !yesUtterance) {
    title = 'Please see our site to start the application process. Scroll to the bottom of this page and select \'First Time Applicant\' from the drop-down menu.';
    links = [
      {
        title: 'visit us here',
        link: 'https://prdwmq.etimspayments.com/pbw/include/weho/rppnewinfo.jsp'
      }
    ];
  } else if (slot === 'parking_permit_type' && message === 'Residential') {
    title = 'Please see our site for renewal instructions.';
    links = [
      {
        title: 'visit us here',
        link: 'https://prdwmq.etimspayments.com/pbw/include/vpportal/weho/login_page.jsp'
      }
    ];
  } else if (slot === 'parking_permit_type' && message === 'Moving') {
    title = 'Please visit this link to complete your request.';
    links = [
      {
        title: 'visit us here',
        link: 'https://go.citygro.ws/permit-parking/resident-moving-truck-van-encroachment-parking-permit-3e436589-2a3c-408e-97a5-5bc0393ac993'
      }
    ];
  } else if (slot === 'guest_parking_permit_type' && message === 'Annual Guest') {
    title = 'Please visit this link to complete your request.';
    links = [
      {
        title: 'visit us here',
        link: 'https://prdwmq.etimspayments.com/pbw/include/vpportal/weho/login_page.jsp'
      }
    ];
  } else if (slot === 'guest_parking_permit_type' && message === 'Permit by Plate') {
    title = 'It looks like you need a Permit-by-Plate permit for your visitor. Please apply here';
    links = [
      {
        title: 'visit us here',
        link: 'https://prdwmq.etimspayments.com/pbw/include/vpportal/weho/rpp_login.jsp'
      }
    ];
  }

  return {title: title, links: links};
};

export const oaklandYardUpkeepLinks = (slot: string, message: string) => {
  const yesUtterance: boolean | undefined = isYesUtterance(message.toLowerCase());

  let title = '';
  let links: { title: string, link: string }[] = [];

  if (slot === 'isPrivateProperty' && yesUtterance) {
    title = 'Please call Code Enforcement at (510) 238-3381 for further assistance with your request or report online at:';
    links = [
      {
        title: 'visit us here',
        link: 'https://www.oaklandca.gov/services/file-a-complaint-with-code-enforcement'
      }
    ];
  }
  return {title: title, links: links};
};

export const formatSearchResult = (items: any) => {
  const searchItems: any = items.slice(0, 4);
  return searchItems.map((item: any, index: number) => {
    return {
      title:item.title===""?item.link:item.title,
      link: item.link
    };
  });
};

export const arrayEqual = (choice: string[], option: string[]) => {
  for (let i=0; i<choice.length; i++) {
    if (choice[i] !== option[i]) {
      return false;
    }
  }
  return true;
}
