import { NOLA_TREE_SERVICE_TYPE, TREE_ON_POWER_LINE, WEST_HOLLYWOOD } from './messages';

export const getNolaWasterContainerQuestions = (wasteContainerAction: string, wasteType: string) => {
  if (!wasteContainerAction) {
    return [
      {
        title: `What is issue address?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
  wasteContainerAction = wasteContainerAction.toLowerCase();
  if (wasteContainerAction.includes('order')) {
    return [
      {
        title: 'Does your residence consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} cart order?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else if (wasteContainerAction.includes('repair') || wasteContainerAction.includes('repari')) {
    return [
      {
        title: 'What is the nature of damage? (handle, lid, wheels, cracked cart)',
        slot: 'damageDescription',
        type: 'input'
      },
      {
        title: 'Does your residence consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} cart repair?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else {
    return [
      {
        title: 'Does your residence consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} cart replacement?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
};

export const getNolaWasteServiceQuestions = (startStop: string) => {
  return [
    {
      title: 'Does your residence consist of more than 4 units?',
      slot: 'isComplex',
      type: 'input'
    },
    {
      title: `What is the address where you would like to ${startStop} the service?`,
      slot: 'address',
      type: 'address'
    }
  ];
};

export const getNolaPropertyAndDamageTypeNullQuestions = () => {
  return [
    {
      title: 'What is the issue at given address?',
      slot: 'userMessage',
      type: 'input'
    }
  ];
};

export const getNolaWastePickupQuestions = (isBulk: boolean, wasteType: string) => {
  if (isBulk) {
    return [
      {
        title: 'Please describe the bulk item. (Note: For tires, only 4 may be put out at a time.)',
        slot: 'bulkItemDescription',
        type: 'input'
      },
      {
        title: 'Does the property consist of more than 4 units?',
        slot: 'isComplex',
        type: 'input'
      },
      {
        title: `What is the address for the ${wasteType} pickup?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
  return [
    {
      title: 'Does the property consist of more than 4 units?',
      slot: 'isComplex',
      type: 'input'
    },
    {
      title: `What is the address for the ${wasteType} pickup?`,
      slot: 'address',
      type: 'address'
    }
  ];
};

export const getNolaTreeIntentQuestions = (treeServiceType: string) => {
  if (!treeServiceType) {
    return [
      {
        title: NOLA_TREE_SERVICE_TYPE,
        slot: 'treeServiceType',
        type: 'single_choice',
        meta: {
          multi: false,
          choices: ['tree service', 'stump removal', 'park maintenance']
        }
      }
    ];
  }
  treeServiceType = treeServiceType.toLowerCase();
  if (treeServiceType.includes('tree service')) {
    return [
      {
        title: TREE_ON_POWER_LINE,
        slot: 'isEmergency',
        type: 'input'
      },
      {
        title: `Are you the property owner?`,
        slot: 'isPropertyOwner',
        type: 'input'
      },
      {
        title: `Are the roots buckling sidewalk, under house, etc?`,
        slot: 'sidewalkBuckled',
        type: 'input'
      },
      {
        title: 'Please indicate which one of the following descriptions applies to your request: ' +
          'tree branches falling, tree is leaning, tree removal/dead tree, tree trim or stump grinding',
        slot: 'treeIssue',
        type: 'input'
      },
      {
        title: `What is the numerical address where you need the tree service?`,
        slot: 'address',
        type: 'address'
      }
    ];
  } else if (treeServiceType.includes('stump removal')) {
    return [
      {
        title: 'What is the numerical address where you need the stump removal?',
        slot: 'address',
        type: 'address'
      }
    ];
  } else {
    return [
      {
        title: `Is this a New Orleans Recreation Development Commission (NORDC) park or facility?`,
        slot: 'isNordc',
        type: 'input'
      },
      {
        title: `What is the numerical address where you need the park maintenance?`,
        slot: 'address',
        type: 'address'
      }
    ];
  }
};

export const PEST_CONTROL_MESSAGE = (pestType: string) => `For ${pestType} issues. Please contact 311 directly.`;

export const getNolaFloodIntentQuestions = (floodingType: string) => {
  if (!floodingType) {
    return [
      {
        title: "Is the street flooded?",
        slot: "isEmergency",
        type: "input",
      },
      {
        title: "Is this an issue with a catch basin or flooding?",
        slot: "floodingType",
        type: "input",
      },
      {
        title: "Is this an issue with a ditch?",
        slot: "isDitch",
        type: "input",
      },
      {
        title: `What is the address of the issue?`,
        slot: "address",
        type: "address",
      },
    ];
  }

  floodingType = floodingType.toLowerCase();

  if (floodingType.includes("flooding")) {
    return [
      {
        title: "Is the street flooded?",
        slot: "isEmergency",
        type: "input",
      },
      {
        title: `What is the address of the flooding?`,
        slot: "address",
        type: "address",
      },
    ];
  } else if (floodingType.includes("culvert")) {
    return [
      {
        title: "Is the street flooded?",
        slot: "isEmergency",
        type: "input",
      },
      {
        title: `What is the issue with the culvert (IE. clogged, damaged)?`,
        slot: "drainIssue",
        type: "input",
      },
      {
        title: `What is the address of the culvert?`,
        slot: "address",
        type: "address",
      },
    ];
  } else if (floodingType.includes("catch basin")) {
    return [
      {
        title: "Is the street flooded?",
        slot: "isEmergency",
        type: "input",
      },
      {
        title: `What is the issue with the catch basin (IE. clogged, damaged)?`,
        slot: "drainIssue",
        type: "input",
      },
      {
        title: `What is the address of the catch basin?`,
        slot: "address",
        type: "address",
      },
    ];
  } else {
    return [
      {
        title: "Is the street flooded?",
        slot: "isEmergency",
        type: "input",
      },
      {
        title: `What is the address of the issue?`,
        slot: "address",
        type: "address",
      },
    ];
  }
};

export const getParkingPermitIntentFlowQuestions = (account_city: string) => {
  const permitTypeChoices = ["Residential", "Guest"];

  if (account_city !== WEST_HOLLYWOOD) {
    permitTypeChoices.push("Moving");
  }

  return [
    {
      title:
        "Do you currently have or have you had a residential parking permit in the last 6 months?",
      slot: "have_parking_permit",
      type: "binary",
      meta: {
        accept_text: "Yes",
        reject_text: "No",
      },
    },
    {
      title:
        "Please tell us the type of parking permit you need. Choose one of the following:",
      slot: "parking_permit_type",
      type: "single_choice",
      meta: {
        multi: false,
        choices: permitTypeChoices,
      },
    },
    {
      title:
        "What type of permit do you need for your guest (Annual Guest or Permit-by-Plate Visitor/Daily Visitor)",
      slot: "guest_parking_permit_type",
      type: "single_choice",
      meta: {
        multi: false,
        choices: ["Annual Guest", "Permit by Plate"],
      },
    },
  ];
};

export function handleBALTIntentSwitching(intent: string) {
  switch (intent) {
    case "baltimorebot_iss_public_property_intent":
    case "baltimorebot_dep_public_property_tcmfootone":
    case "baltimorebot_dep_public_property_curbrepa":
    case "baltimorebot_dep_public_property_steelpltwo":
    case "baltimorebot_dep_public_property_wwsurfac":
      intent = handleBALTPotholeCase(intent);
      return intent;
    case "baltimorebot_dep_grass_mowing_LOTCUTSC_intent":
    case "baltimorebot_dep_grass_mowing_PRGRASSC_intent":
    case "baltimorebot_dep_grass_mowing_HCDSPROP_intent":
      intent = handleBALTGrassMowingCase(intent);
      return intent;
    case "baltimorebot_dep_traffic_signal_TRSIWATI_intent":
      intent = "signal timing issue";
      return intent;
    case "baltimorebot_dep_water_meter_issue_wwwateten_intent":
      intent = "water leak issue";
      return intent;
    case "baltimorebot_dep_sewage_issue_wwwstorm_intent":
      intent = "storm water in basement issue";
      return intent;
    case "baltimorebot_dep_street_light_trmrpstr_intent":
    case "baltimorebot_dep_street_light_bgestl":
    case "balitmorebot_dep_street_light_streetlsix":
    case "baltimorebot_dep_street_light_bgestlone":
      intent = handleBALTStreetLightCase(intent);
      return intent;
    case "baltimorebot_dep_req_repair_trashcan_swcorner":
    case "baltimorebot_dep_req_repair_trashcan_parkcan":
    case "baltimorebot_dep_req_repair_park_rpparkma":
    case "baltimorebot_dep_req_repair_park_schedule":
      intent = handleBALTTrahCanCase(intent);
      return intent;
    case "baltimorebot_dep_noise_intent_bclbllc":
      intent = "liqour license violation issue";
      return intent;
    case "baltimore_dep_graffiti_intent_public":
    case "baltimorebot_dep_graffiti_intent_grffiown":
    case "baltimorebot_dep_graffiti_intent_hcdgraff":
      intent = handleBALTGraffitiCase(intent);
      return intent;
    case 'baltimorebot_dep_animal_services_zzzhcomp':
    case 'baltimorebot_dep_animal_services_antrapvb':
    case 'baltimorebot_dep_animal_services_anatraps':
    case 'baltimorebot_dep_animal_attack_against_human':
    case 'baltimorebot_dep_animal_attack_against_animal':
      intent = "animal services";
      return intent;            
    default:
      return intent;
  }
}

function handleBALTPotholeCase(intent: string) {
  let issueIntent = intent.toLowerCase();
  if (issueIntent.includes("tcmfootone")) {
    issueIntent = "footwalk/sidewalk repair";
  } else if (issueIntent.includes("curbrepa")) {
    issueIntent = "curb repair";
  } else if (issueIntent.includes("steelpltwo")) {
    issueIntent = "steel plate problem";
  } else if (issueIntent.includes("wwsurfac")) {
    issueIntent = "water surface repair";
  } else {
    issueIntent = "pothole";
  }
  return issueIntent;
}

function handleBALTGrassMowingCase(intent: string) {
  let issueIntent = intent.toLowerCase();
  if (issueIntent.includes("lotcutsc")) {
    issueIntent = "high grass on street medians";
  } else if (issueIntent.includes("prgrassc")) {
    issueIntent = "park grass cutting issue";
  } else {
    issueIntent = "trash/debris on private property";
  }
  return issueIntent;
}

function handleBALTStreetLightCase(intent: string) {
  let issueIntent = intent.toLowerCase();
  if (issueIntent.includes("trmrpstr")) {
    issueIntent = "street light repair";
  } else if (
    issueIntent.includes("bgestl") ||
    issueIntent.includes("bgestlone")
  ) {
    issueIntent = "street light out";
  } else {
    issueIntent = "street light too bright issue";
  }
  return issueIntent;
}

function handleBALTTrahCanCase(intent: string) {
  let issueIntent = intent.toLowerCase();
  if (issueIntent.includes("swcorner")) {
    issueIntent = "trash can removal request";
  } else if (issueIntent.includes("parkcan")) {
    issueIntent = "trash can remove from park issue";
  } else if (issueIntent.includes("rpparkma")) {
    issueIntent = "park maintenance issue";
  } else {
    issueIntent = "bulk trash pickup issue";
  }
  return issueIntent;
}

function handleBALTGraffitiCase(intent: string) {
  let issueIntent = intent.toLowerCase();
  if (issueIntent === "baltimore_dep_graffiti_intent_public") {
    issueIntent = "graffiti removal";
  } else {
    issueIntent = "graffiti issue"
  }
  return issueIntent;
}
